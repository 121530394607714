.chatContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  height: 100vh;
  width: 100%;
  margin: 0 auto;
  background: transparent;
  border-radius: 0 0 12px 12px;
  overflow: hidden;
  position: relative;
}

.messagesContainer {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #393939;
}

.message {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  max-width: 85%;
  margin-bottom: 4px;
}

.userMessage {
  margin-left: auto;
  flex-direction: row-reverse;
}

.botMessage {
  margin-right: auto;
  position: relative;
}

.messageContent {
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.4;
  box-shadow: none;
}

.userMessage .messageContent {
  background: #1e1e1e;
  color: #e0e0e0;
  border: 1px solid #424242;
  border-top-right-radius: 4px;
  margin-right: 8px;
}

.botMessage .messageContent {
  background: #1e1e1e;
  color: #e0e0e0;
  border: 1px solid #424242;
  border-top-left-radius: 4px;
  margin-left: 8px;
}

.botAvatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(45deg, var(--skyBlueLight), var(--skyBlue));
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  position: relative;
  z-index: 1;
  border: 1px solid #424242;
}

.botAvatarInner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: white;
  opacity: 0.2;
}

.inputContainer {
  padding: 16px;
  background: #171717;
  border-top: 1px solid #30363d;
  height: 140px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
}

.contextSelect {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.contextSelect label {
  font-size: 14px;
  color: #666;
}

.select {
  padding: 8px 12px;
  border: 1px solid #ececec;
  border-radius: 8px;
  font-size: 14px;
  outline: none;
  background-color: white;
  cursor: pointer;
  transition: border-color 0.2s;
}

.select:focus {
  border-color: #007aff;
}

.inputForm {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  flex-grow: 1;
  background: transparent;
}

.inputWrapper {
  position: relative;
  width: 100%;
  flex-grow: 1;
  display: flex;
  background: transparent;
}

.input {
  width: 100%;
  min-height: 100px;
  padding: 12px;
  border: 1px solid rgba(51, 51, 51, 0.8);
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.5;
  transition: border-color 0.15s ease;
  background: rgba(45, 45, 45, 0.6);
  color: rgba(255, 255, 255, 0.9);
  flex-grow: 1;
  backdrop-filter: blur(4px);
  outline: none;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-y: auto;
}

.input:empty:before {
  content: attr(placeholder);
  color: rgba(255, 255, 255, 0.5);
}

.input:focus {
  outline: none;
  border-color: rgba(74, 74, 74, 0.8);
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.1);
  background: rgba(45, 45, 45, 0.7);
}

.input :global(.contextText) {
  background-color: rgba(0, 123, 255, 0.1);
  border-radius: 3px;
  padding: 2px 4px;
  margin: 0 2px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #ffffff;
  white-space: nowrap;
}

.contextPillContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  padding: 0 4px;
  margin-bottom: 8px;
}

.contextPill {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 2px 6px;
  background: #2d2d2d;
  color: #e0e0e0;
  border: 1px solid #424242;
  border-radius: 4px;
  font-size: 13px;
  height: 22px;
  line-height: 1;
  font-family: monospace;
}

.removeContext {
  background: none;
  border: none;
  color: #888;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  display: flex;
  align-items: center;
}

.removeContext:hover {
  color: #e0e0e0;
}

.contextList {
  flex: 1;
  overflow-y: auto;
  margin-top: 8px;
  height: calc(100% - 28px); /* Subtract header height */
}

.contextOption {
  padding: 8px 12px;
  background: none;
  border: none;
  border-radius: 4px;
  text-align: left;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
  color: #333;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contextOption:hover {
  background: #f5f5f5;
  color: #007aff;
}

.sendButton {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: #388bfd;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s;
  margin-bottom: 4px;
}

.sendButton:hover {
  opacity: 0.8;
}

.sendButton:active {
  opacity: 0.6;
}

.input:disabled,
.sendButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(1px);
}

.modalContent {
  width: 600px;
  background: rgba(30, 30, 30, 0.95);
  backdrop-filter: blur(8px);
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.24);
  overflow: hidden;
  color: white;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.15);
  animation: modalFadeIn 0.2s ease-out;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: scale(0.98);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.close_icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: white;
  font-size: 30px;
}

.modalHeader {
  padding: 20px 24px;
  border-bottom: 1px solid #333;
  flex-shrink: 0;
}

.modalHeader h3 {
  margin: 0;
  font-size: 18px;
  color: white;
}

.instructions {
  margin: 8px 0 0;
  color: #a0a0a0;
  font-size: 14px;
}

.searchModalInline {
  position: absolute;
  background: #1e1e1e;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3);
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #333;
  z-index: 1001;
  width: 100%;
  bottom: 100%;
  margin-bottom: 4px;
}

.typeList {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.typeItem {
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(255, 255, 255, 0.9);
}

.typeItem:hover,
.typeItem.highlighted {
  background: rgba(255, 255, 255, 0.1);
}

.folderIcon {
  margin-right: 12px;
  font-size: 16px;
}

.modal {
  background: #1e1e1e;
  color: white;
  width: 100%;
}

.modal h3 {
  margin: 0 0 8px 0;
  color: #666;
  font-size: 14px;
  box-sizing: border-box;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  height: 20px;
}

.modalCloseButton {
  margin-top: 16px;
  padding: 8px 16px;
  background: #007aff;
  color: white;
  border: none;
  border-radius: 0;
  cursor: pointer;
  transition: opacity 0.2s;
}

.modalCloseButton:hover {
  opacity: 0.8;
}

.inputHint {
  padding: 0 4px;
  margin-bottom: 4px;
}

.hintText {
  font-size: 12px;
  color: #8b949e;
  display: flex;
  align-items: center;
  gap: 4px;
}

.atSymbol {
  color: #388bfd;
  font-weight: 500;
}

.sparkleIcon {
  position: absolute;
  left: 9px;
  top: 13px;
  margin-top: -4px;
  color: #fff;
  font-size: 30px;
}

.noResults {
  padding: 12px 16px;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
}

.searchResultsWrapper {
  position: absolute;
  z-index: 1100;
  display: flex;
}

.searchResults {
  min-width: 200px;
  max-width: 300px;
  background: rgba(30, 30, 30, 0.95);
  backdrop-filter: blur(8px);
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(51, 51, 51, 0.8);
}

.searchResultsContainer {
  padding: 8px 0;
}

/* Add styles for positioning helper */
.selectedContexts {
  display: flex;
  gap: 8px;
  padding: 8px 24px;
  border-bottom: 1px solid #333;
  flex-wrap: wrap;
}

.contextItem {
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 4px;
  background: #2d2d2d;
}

.contextLabel {
  margin-right: 4px;
  font-weight: 500;
}


.contextLabel.region {
  color: #10b981;
}

.contextLabel.person {
  color: #3b82f6;
}

.contextLabel.education {
  color: #ff7f50;
}

.contextLabel.fund {
  color: #d4d10b;
}

.contextLabel.org {
  color: #9370db;
}

.contextLabel.eng {
  color: #ff69b4;
}

.contextLabel.default {
  color: #808080;
}

.contextValue {
  color: #d1d5db;
}

.loadingIndicator {
  padding: 12px 16px;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
}
