.root {
  justify-self: end;
  /* padding: 10px 20px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 145px;
  box-sizing: border-box;
  /* gap: 1rem; */
  padding: 10px;
  margin-top: auto;
}

.dataDate {
  height: 10px;
  margin-top: 10px;
  font-size: 10px;
  font-style: italic;
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: 700;
  justify-content: center;
}

.root_button {
  position: relative;
  width: 100%;
}

.root_button:hover {
  cursor: pointer;
}

.user {
  display: flex;
  align-items: center;
  justify-content: start;
}

.badge {
  background-color: var(--theme-avtar-bg);
  border-radius: 50%;
  color: var(--theme-page-text-secondary);
  font-size: 14px;
  height: 32px;
  min-width: 32px;
  line-height: 32px;
  text-align: center;
}

.user_names {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 48px;
  padding: 10px;
  border-radius: 10px;
}

.user_names_active {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  background-color: #2b2c2e;
}

.user_names:hover {
  background-color: #2b2c2e;
}

.chevron {
  color: var(--theme-page-text-secondary);
  margin-top: 4px;
  margin-left: 10px;
  width: 20px;
  height: 20px;
}

.name_wrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 10px;
}

.pinned_wrap {
  margin-left: -13px;
}

.bottom_sidebar_model {
  z-index: 1;
  position: absolute;
  bottom: 70px;
  align-self: center;
  border-radius: 10px;
}

.model_title > span {
  color: white;
  font-size: 0.875rem;
}

.logout_icon {
  color: aliceblue;
}

.divider {
  background-color: aliceblue;
}

.feedBackButton {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  border-radius: 10px;
  padding: 5px;
  height: 48px;
  margin-bottom: 4px;
}

.feedBackButton:hover {
  background-color: var(--theme-sidebar-list_hover);
}

.feedback_text {
  font-size: 16px;
  color: white;
  font-weight: 700;
}

.star_icon {
  margin-inline: 10px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
}
