.feedback_button {
  position: absolute;
  bottom: 5px;
  left: 15px;
  font-size: 35px;
  color: var(--charcoalLight);
  transition: all ease-in-out 0.2s;
  cursor: pointer;
  border-radius: 50%;
}

.feedback_button:hover {
  color: var(--skyBlue);
}

.root {
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.card {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  padding: 30px;
  font-size: 10px;
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  min-width: 450px;
  min-height: 400px;
}

.header {
  display: flex;
  justify-content: center;
}

.subheader {
  text-align: center;
  display: flex;
  justify-content: center;
  color: #333;
  font-size: 13px;
  width: 400px;
  word-wrap: break-word;
}

.label {
  font-weight: 700;
  margin-bottom: 8px;
  font-size: 14px;
  margin-top: 14px;
}

.input {
  color: #333;
  font-size: 1rem;
  padding: 12px 1rem;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #c3c3c3;
  display: flex;
  align-items: center;
  outline: none;
  width: 100%;
}

.input:focus {
  border: 2px solid #0266d5;
}

.textarea {
  border-radius: 4px;
  height: 250px;
  border: 1px solid #c3c3c3;
  padding: 5px;
  resize: none;
}

.button_wrapper {
  display: flex;
  flex-direction: column;
  margin: 30px 0 0;
  gap: 20px;
}

.base_button {
  text-decoration: none;
  display: flex;
  height: 40px;
  padding: 1rem 2rem;
  font-size: 14px;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 200px;
  font-weight: 600;
  background-color: #ffffff;
  border: 1px solid #c3c3c3;
  color: #111010;
}

.submit_button {
  text-decoration: none;
  display: flex;
  height: 40px;
  padding: 1rem 2rem;
  font-size: 14px;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 200px;
  font-weight: 600;
  background-color: #ffffff;
  border: 1px solid #c3c3c3;
  color: #111010;
  background-color: #0266d5;
  color: #fff;
  border-color: #0266d5;
}

.base_button:hover {
  background-color: #b80024;
  color: #fff;
  border-color: #b80024;
}

.submit_button:hover {
  background-color: #0266d5;
  color: #fff;
  border-color: #0266d5;
}

.error {
  color: #b80024;
  font-size: 14px;
}

.lds-ring {
  box-sizing: border-box;
  display: block;
  width: 24px;
  height: 24px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.success_icon {
  font-size: 35px;
  color: var(--lime);
}

.success_content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 17px;
  font-size: 18px;
}

.card_success {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  padding: 30px;
  font-size: 10px;
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  min-width: 450px;
  min-height: 200px;
  align-items: center;
  justify-content: center;
}
